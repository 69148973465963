<template>
  <div>
    <van-form class="form-wrap" @submit="onSubmit">
      <van-field
        v-model="merchantStoreName"
        name="门店名称"
        label="门店名称"
        placeholder="请输入门店名称"
        :rules="[{ required: true, message: '请输入门店名称' }]"
      /> 
      <van-field
        v-model="merchantStoreAddress"
        name="门店地址"
        label="门店地址"
        placeholder="请输入门店地址"
        :rules="[{ required: true, message: '请输入门店地址' }]"
      />
      <van-field
        v-model="account"
        name="门店账号"
        label="门店账号"
        placeholder="请输入门店账号"
        :rules="[{ required: true, message: '请输入门店账号' }]"
      />
      <van-field
        v-if="id"
        v-model="password"
        name="原密码"
        label="原密码"
        type="password"
        placeholder="请输入原密码"
        :rules="[{ required: true, message: '请输入原密码' }]"
      />
      <van-field
        v-else
        v-model="password"
        name="密码"
        label="密码"
        type="password"
        placeholder="请输入密码"
        :rules="[{ required: true, message: '请输入密码' }]"
      />
      <van-field
        v-if="id"
        v-model="newPassword"
        name="新密码"
        label="新密码"
        type="password"
        placeholder="请输入新密码"
        :rules="[{ required: true, message: '请输入新密码' }]"
      />
      <van-field
        v-model="confirmPassword"
        name="确认密码"
        label="确认密码"
        type="password"
        placeholder="请输入确认密码"
        :rules="[{ required: true, message: '请输入确认密码' }]"
      />
      <div style="margin-top: 30px;">
        <van-button round block class="btn-submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { postAddStore, postModifyStore, getStore } from 'api/common'
export default {
  data() {
    return {
      id: "",
      account: "",
      merchantStoreName: "",
      merchantStoreAddress: "",
      password: "",
      newPassword: "",
      confirmPassword: ""
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      getStore(this.id).then(res => {
        console.log('getStore', res);
        if (res.code === 200) {
          const data = res.data
          this.account = data.account
          this.merchantStoreName = data.merchantStoreName
          this.merchantStoreAddress = data.merchantStoreAddress
        }
      })
    }
  },
  methods: {
    onSubmit() {
      if (this.id) {
        if (this.password === this.newPassword) {
          this.$toast.fail('新密码不能与原密码一致');
          return
        }
        if (this.newPassword !== this.confirmPassword) {
          this.$toast.fail('新密码与确认密码不一致');
          return
        }
      } else {
        if (this.password !== this.confirmPassword) {
          this.$toast.fail('密码与确认密码不一致');
          return
        }
      }
      
      const formData = {
        merchantId: JSON.parse(localStorage.getItem("payInfo")).id,
        account: this.account,
        merchantStoreName: this.merchantStoreName,
        merchantStoreAddress: this.merchantStoreAddress,
        password: this.confirmPassword,
        enableStatus: true
      };

      if (this.id) {
        formData.id = this.id
      }

      const func = this.id ? postModifyStore: postAddStore
      func(formData).then(res => {
        if (res.code === 200) {
          this.$toast.success(`${this.id ? '编辑': '新增'}门店成功`);
          this.$router.back(-1)
        } else {
          const msg = res.msg || `${this.id ? '编辑': '新增'}门店失败`
          this.$toast.fail(msg);
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.form-wrap {
  border-radius: 5px;
  margin: 10px;

  .btn-submit {
    background: linear-gradient(89deg, #ff9446 10%, #fd6e05 81%);
    border-radius: 5px;
    color: #fff;
  }
}
</style>
